#form-input-control-email .ui.form {
    color: white;
}
header {

    color: whitesmoke;
    background-color: black;
}

#smooth-scroll-container {
    height: 100vh;
   

}

.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
        left: 0;
        right: 0;
}

#topOfPage {
    width: 100vw;
    height: 100vh;
}

.current-forecast {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

#currentWeather {
    font-size: 1.75em;
    display: flex;
    justify-content: space-between;
    min-width: 100%;    
}

.current-weather-text {
   background-color:  rgb(33, 37, 41, 0.5);
    margin: 2rem;
    padding: 2rem;
    
}

.weather-text-boxes {
    background-color: rgb(255, 255, 255, .4);
    color: rgb(52, 49, 49);
    font-weight:600;
    border: solid rgb(147, 142, 142);
    border-width: 1px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    padding: .3rem;  
}

.noaa-alerts-container
 {
    background-color: rgb(255, 255, 255, .5);
        color: rgb(52, 49, 49);
        font-weight: 600;
        border: solid rgb(147, 142, 142);
        border-width: 1px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1rem;
        padding: 1rem 0rem;
        padding-bottom: 1rem;
        margin: 2rem 0rem;

 }
.weather-text-box-border {
    border: solid white;
    display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    border-width: 1px;
    border-radius: 15px;
    padding: 2rem 0rem;
    height: 100%;
    width: 100%;
    
}


.current-weather-bold-text {
    font-weight: 500;
}

.please-search {
    border: solid rgb(147, 142, 142);
        border-width: 1px;
        border-radius: 15px;
}

.weather-text-boxes-extended {
    border: solid rgb(147, 142, 142);
    border-width: 1px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0rem;

}

.chart-container {
    /* margin-top: 1rem; */
    background: #222;
    color: #fff;
    border-radius: 15px;
    padding: 1rem;
    margin-top: 5rem;
    border: solid white;
    border-width: 1px;
}

#forecastCard {

    background-color: black;
    color: white;
    box-shadow: 0px 3px 3px grey;
}

#mainNav {
    background: black;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 5px;

    color: white;
}


.search-button {
    margin: 0rem 1rem;
    color: white;
    background-color: black;
    border: none;

}

.button {
    background-color: grey;
}

.medium-icon {
    height: 2rem;
    width: 2rem;
    padding-bottom: .5rem;
}

.hidden {
    display: none;
}

.contact-cards {
    color: white;
    border: solid white;
    border-radius: 15px;
    border-width: 1px; 
    margin: 1rem;
    padding: 2rem 1rem 1rem 1rem;
}





